import React, { useContext } from 'react'
import Firebase from 'gatsby-plugin-firebase'

import { AuthProvider, AuthContext } from '../components/auth/auth'
import Layout from '../components/layout'
import UserProfile from '../components/userProfile'

const Profile = () => {
  return (
    <AuthProvider>
      <Layout>
        <UserProfile />
      </Layout>
    </AuthProvider>
  )
}

export default Profile