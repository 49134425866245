import React, { useContext } from 'react'
import Firebase from 'gatsby-plugin-firebase'

import { AuthProvider, AuthContext } from './auth/auth'
import Layout from './layout'

const UserProfile = () => {
  const {currentUser} = useContext(AuthContext)
  return (
    <AuthProvider>
      {currentUser ? <button onClick={() => Firebase.auth().signOut()}>Sign out</button> : null}
    </AuthProvider>
  )
}

export default UserProfile